import Vue from 'vue'

Vue.filter('toCurrency', value => {
  if (typeof value !== 'number') {
    value = Number(value)
  }
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
  return formatter.format(value)
})

<template>
  <b-modal
    id="prize-draw-process-modal"
    v-model="open"
    title="Sorteio Processado"
    size="sm"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    centered
    ok-only
    ok-title="Fechar"
    @ok="close"
  >
    <b-card-body>
      O processamento do sorteio <b>{{ prizeDraw.name || '' }}</b> foi concluído.
    </b-card-body>
  </b-modal>
</template>
<script>
export default {
  name: 'PrizeDrawProcessModal',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    prizeDraw: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    close() {
      this.$emit('onClose')
    },
  },
}
</script>

<style scoped>

</style>

const prizeDraw = [
  {
    path: '/prize-draw',
    name: 'prize-draw.list',
    component: () => import('@/views/prize-draw/PrizeDrawList.vue'),
  },
  {
    path: '/proccess-prize-draw',
    name: 'process.prizedraw',
    component: () => import('@/views/prize-draw/ProcessPrizeDraw.vue'),
  },
  {
    path: '/participant-validate',
    name: 'prize-draw.participant.validate',
    component: () => import('@/views/participants/ParticipantCouponValidate.vue'),
  },
]

export default prizeDraw

import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import loader from './loader'
import app from './app'
import appConfig from './app-config'
import typeIndex from './types/type_index'
import auth from './auth/auth'
import prizeDraw from './prize-draw/prize-draw'
import verticalMenu from './vertical-menu'
import webhookTopics from './webhook/topics'
import webhookEvents from './webhook/events'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    loader,
    auth,
    prizeDraw,
    typeIndex,
    webhookTopics,
    webhookEvents,
  },
  strict: process.env.DEV,
})

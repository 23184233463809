const state = {
  topics: [],
}

const getters = {

}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_TOPICS(state, payload) {
    state.topics = payload
  },
}

const actions = {
  getTopics: async ({ commit, state }) => {
    try {
      if (state.topics.length > 0) {
        return state.topics
      }
      const data = await window.http.get('webhooks/topics')
      commit('SET_TOPICS', data)

      return data
    } catch (error) {
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ModalPlugin, ToastPlugin } from 'bootstrap-vue'
import '@/libs/toastification'
import ApiDelete from '@/libs/actions/deleteAction'

class Notify {
  $vm = Vue

  show(type, text, icon) {
    this.$vm.$toast({
      component: ToastificationContent,
      props: {
        title: text,
        icon: icon || 'EditIcon',
        variant: type,
      },
    })
  }

  success(text, icon = null) {
    this.show('success', text, icon)
  }

  error(text, icon = null) {
    this.show('danger', text, icon)
  }

  warning(text, icon = null) {
    this.show('warning', text, icon)
  }
}

Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
const notify = new Notify()
Vue.prototype.$notify = notify
window.notify = notify
export default Notify

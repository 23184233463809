import Vue from 'vue'

const ApiDelete = {
  async execute(endPoint, message, title, onConfirm, data) {
    const vm = new Vue()
    return vm.$bvModal.msgBoxConfirm(
      message,
      {
        title,
        size: 'sm',
        okVariant: 'danger',
        okTitle: 'Sim',
        cancelTitle: 'Não',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      },
    )
      // eslint-disable-next-line consistent-return
      .then(async value => {
        if (value) {
          const response = await window.http.delete(endPoint, { data })
          if (response.error) {
            window.notify.error(response.error_message)
            return false
          }
          return onConfirm()
        }
        return true
      })
  },
}
Vue.prototype.$apiDelete = ApiDelete
window.apiDelete = ApiDelete
export default ApiDelete

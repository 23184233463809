import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'
import password from '@/router/password'
import users from '@/router/users'
import types from '@/router/types'
import clients from '@/router/clients'
import promotions from '@/router/promotions'
import prizeDraw from '@/router/prize-draw'
import participants from '@/router/participants'
import ineligibleList from '@/router/ineligible-list'
import systemSettings from '@/router/system-settings'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginCenter.vue'),
    meta: {
      public: true,
      layout: 'full',
    },
  },
  {
    path: '/public/participants',
    name: 'public.participants',
    component: () => import('@/views/public/Participants.vue'),
    meta: {
      public: true,
      layout: 'full',
    },
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      pageTitle: 'Home',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mail-templates',
    name: 'mail.list',
    component: () => import('@/components/i2/forms/elements/MailEditor/EmailTemplateList.vue'),
  },
  {
    path: '/mail-template/:uuid',
    name: 'mail',
    component: () => import('@/components/i2/forms/elements/MailEditor/EmailEditor.vue'),
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    ...routes,
    ...password,
    ...users,
    ...types,
    ...clients,
    ...promotions,
    ...prizeDraw,
    ...participants,
    ...ineligibleList,
    ...systemSettings,
  ],
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'public.participants'
      || to.name === 'error-404'
      || to.name === 'login'
      || to.name === 'perdi-minha-senha'
      || to.name === 'recuperar-senha'
      || to.meta.public) {
    return next()
  }
  if (to.name && to.name.length) {
    if (!isUserLoggedIn() && !to.name === 'login') {
      router.push('login')
      return false
    }
  }
  next()
  return true
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router

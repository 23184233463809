import Vue from 'vue'
import {
  BAlert, BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader, BCardSubTitle,
  BCardText,
  BCardTitle,
  BCol, BContainer,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BImg,
  BInputGroup,
  BInputGroupAppend, BMedia,
  BRow,
  BSidebar,
  BTab,
  BTabs,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import I2FormInputMoney from '@/components/i2/forms/elements/I2FormInputMoney.vue'
import I2IconButton from '@/components/i2/forms/elements/I2IconButton.vue'

Vue.component('BFormInput', BFormInput)
Vue.component('BInputGroup', BInputGroup)
Vue.component('BAvatar', BAvatar)
Vue.component('BFormGroup', BFormGroup)
Vue.component('BButton', BButton)
Vue.component('BInputGroupAppend', BInputGroupAppend)
Vue.component('BRow', BRow)
Vue.component('BCol', BCol)
Vue.component('BForm', BForm)
Vue.component('BAlert', BAlert)
Vue.component('BBadge', BBadge)
Vue.component('BTabs', BTabs)
Vue.component('BFormTextarea', BFormTextarea)
Vue.component('BTab', BTab)
Vue.component('BImg', BImg)
Vue.component('BCard', BCard)
Vue.component('BCardCode', BCardCode)
Vue.component('BDropdownItem', BDropdownItem)
Vue.component('BDropdown', BDropdown)
Vue.component('BFormInvalidFeedback', BFormInvalidFeedback)
Vue.component('BSidebar', BSidebar)
Vue.component('vSelect', vSelect)
Vue.component('ToastificationContent', ToastificationContent)
Vue.component('BCardBody', BCardBody)
Vue.component('BFormCheckbox', BFormCheckbox)
Vue.component('I2FormInputMoney', I2FormInputMoney)
Vue.component('BCardTitle', BCardTitle)
Vue.component('BCardSubTitle', BCardSubTitle)
Vue.component('BCardHeader', BCardHeader)
Vue.component('BCardText', BCardText)
Vue.component('BFormFile', BFormFile)
Vue.component('I2IconButton', I2IconButton)
Vue.component('BMedia', BMedia)
Vue.component('BContainer', BContainer)

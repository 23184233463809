import Vue from 'vue'

const currentUser = {

  getCurrentUser() {
    const userData = localStorage.getItem('userData')
    if (!userData) {
      return null
    }
    return JSON.parse(userData)
  },
  getCurrentPromotion() {
    const user = this.getCurrentUser()
    if (!user) {
      return null
    }
    return user.current_promotion || null
  },

  getCurrentPrizeDraw() {
    const user = this.getCurrentUser()
    if (!user) {
      return null
    }
    return user.current_prize_draw || null
  },
}
Vue.prototype.$user = currentUser
window.user = currentUser
export default currentUser

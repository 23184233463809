const state = {
  events: [],
}

const getters = {

}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_EVENT(state, payload) {
    state.topics = payload
  },
}

const actions = {
  saveEvent: async ({ commit, state }, data) => {
    try {
      let response = { error: null }
      if (data.uuid) {
        response = await window.http.put(`webhooks/events/${data.uuid}`, data)
      } else {
        response = await window.http.post('webhooks/events', data)
      }
      return response
    } catch (error) {
      return error
    }
  },
  deleteEvent: async ({ commit, state }, item) => {
    const route = `webhooks/events/${item.uuid}`
    return window.apiDelete.execute(
      route,
      'Confirma a exclusão do web',
      'Excluir',
      () => {
        window.notify.success('Webhook removido com sucesso.')
        return true
      },
    )
  },
  getEvents: async ({ commit, state }, promotion) => window.http.get(`webhooks/events/?promotion=${promotion}`),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import { $themeConfig } from '@themeConfig'

let { isCollapsed } = $themeConfig.layout.menu
const storageValue = window.localStorage.getItem('menu_pin_status')
if (storageValue !== null) {
  isCollapsed = storageValue === 'true'
}
export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: isCollapsed,
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
      window.localStorage.setItem('menu_pin_status', val)
    },
  },
  actions: {},
}

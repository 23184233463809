const users = [
  {
    path: '/participants',
    name: 'participants.list',
    component: () => import('@/views/participants/ParticipantList.vue'),
  },
  {
    path: '/participants/unsubscribe-requests',
    name: 'unsubscribe-requests.list',
    component: () => import('@/views/participants/UnsubscribeRequest.vue'),
  },
  {
    path: '/participants/:uuid',
    name: 'participants.show',
    component: () => import('@/views/participants/ParticipantShow.vue'),
  },
]

export default users
